import axios from "axios";
import ErrorInterceptor from "./interceptors/error.interceptor";
import HttpRequestInterceptor from "./interceptors/http.request.interceptor";
import constant from "../constant";

const http = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/api/`,
  timeout: constant.apiTimeout
});

export const askMeHttp = axios.create({
  baseURL: `${process.env.REACT_APP_AKYME_API_URL}/`,
});

export const crewBoHttp = axios.create({
  baseURL: `${process.env.REACT_APP_BO_API_URL}/`,
});

HttpRequestInterceptor(http);
ErrorInterceptor(http);

HttpRequestInterceptor(askMeHttp);
ErrorInterceptor(askMeHttp);

HttpRequestInterceptor(crewBoHttp);
ErrorInterceptor(crewBoHttp);

export default http;
