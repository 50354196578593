import React, { useEffect, useState } from "react";
import {
  Brush,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { stringToHslColor } from "../../../helper/helper";
import CustomDropdown from "../CustomDropdown";

const PredLineChart = (props) => {
  const { config, min, max } = props;
  const [activeLine, setActiveLine] = useState(config?.datakeys[1]);

  const COLORS = ["#8884d8", "#82ca9d", "#0088FE", "#FF8042", '#424242'];
  const renderXAxis = () => <XAxis dataKey={config.datakeys[0]} />;

  const renderYAxis = () => {
    return <YAxis />;
  };

  const renderDropdown = () => {
    const list = config.datakeys.slice();
    list.splice(0, 1)
    return (
      <div className="d-flex align-items-center justify-content-between w-15">
        <p className="p-0 m-0" style={{ width: 150 }}>Select Axis</p>
        <CustomDropdown
          name=""
          options={list}
          value={activeLine}
          onChange={line => setActiveLine(line)}
        />
      </div>)
  }

  const renderLegend = () => {
    return <Legend verticalAlign="bottom" height={36} />;
  };

  const renderChartData = () => {
    if (config.datakeys && config.datakeys.length) {
      // return config.datakeys.map((item, index) => {
      //   const backgroundColor = COLORS[index];
      //   if (index > 0)
      const index = config.datakeys.indexOf(activeLine);
      return (
        <Line
          type="basis"
          dataKey={activeLine}
          stroke={COLORS[index]}
          dot={false}
        />
      );
      //   });
    }
  };

  const renderRefLines = () => {

    return (
      <>
        {max && <ReferenceLine y={max} label={`Max ${max}`} stroke="green" strokeDasharray="3 3" />}
        {/* <ReferenceLine y={config.stats.mean + (3 * (config.stats.sd))} label={`SD + ${config.stats.sd}`} stroke="orange" strokeDasharray="3 3" />
        <ReferenceLine y={config.stats.mean - (3 * (config.stats.sd))} label={`SD - ${config.stats.sd}`} stroke="orange" strokeDasharray="3 3" /> */}
        {min && <ReferenceLine y={min} label={`Min ${min}`} stroke="green" strokeDasharray="3 3" />}
      </>
    )
  }

  const render = () => {
    if (config && config.data && config.data.length)
      return (
        <div className="d-flex flex-column gap-1 text-center">
          <div className="d-flex justify-content-between align-items-center px-3">
            <h4 className="capitalize align-self-center">{config.title}</h4>
            {renderDropdown()}
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              width={500}
              height={300}
              data={config.data}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              {renderXAxis()}
              {renderYAxis()}
              {config.tooltip ? (
                <Tooltip content={config.tooltip} />
              ) : (
                <Tooltip />
              )}
              {renderLegend()}
              {renderChartData()}
              {renderRefLines()}
              {/* <Brush /> */}
            </LineChart>
          </ResponsiveContainer>
        </div>
      );
    // return <p>No data found</p>;
  };
  return render();
};

export default PredLineChart;
