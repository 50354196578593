import React, { useEffect, useState } from "react";
import CustomLoader from "../../common/CustomLoader";
import constant from "../../../helper/constant";
import { createAnalyticsDashboard, createAnalyticsDashboardView, getAnalyticsDashboardList } from "../../../service/ai-model.service";
import { Link, useParams } from "react-router-dom";
import { useAIModelContext } from "../../../context/ai-model.context";
import { ageAgo } from "../../../helper/helper";
import { useScreenSizeContext } from "../../../context/mobile-layout.context";
import { useAuthContext } from "../../../context/auth.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

const InferenceTab = ({ templateId }) => {
  const params = useParams();
  const { selectedPredictionModel } = useAIModelContext();
  const [workflowList, setWorkflowList] = useState([]);
  const { isMobileScreen } = useScreenSizeContext();
  const { userInfo } = useAuthContext();

  useEffect(() => {
    getInferenceDashboard();
  }, [templateId, params.id, selectedPredictionModel.taskId]);

  const onSeeDetails = (viewId) => {
    const { taskId } = selectedPredictionModel;
    window.open(
      `/${constant.PAGES.INFERENCE_DASHBOARD}?viewId=${viewId}&id=${templateId || params.id}&taskId=${taskId}`,
      "_blank",
      "noopener noreferrer"
    );
  };

  const getInferenceDashboard = () => {
    const { taskId } = selectedPredictionModel;
    if (params)
      getAnalyticsDashboardList(templateId || params.id, taskId)
        .then((res) => {
          if (res && res.length) {
            setWorkflowList(res);
          } else {
            createInferenceDashboard();
          }
        })
        .catch((e) => console.log(e))
        .finally(() => { });
  };

  const createInferenceDashboard = () => {
    const { taskId } = selectedPredictionModel;
    if (params)
      createAnalyticsDashboard(templateId || params.id, taskId)
        .then((r) => {
          const viewId = r;
          return Promise.resolve();
          // return createAnalyticsDashboardView(templateId || params.id, taskId, viewId)
        }).then(() => getInferenceDashboard())
        .catch((e) => console.log(e))
        .finally(() => { });
  };

  const renderWorkflowList = () => {
    if (workflowList.length) {
      return workflowList.map((workspace, index) => {
        const { viewName, viewId, viewDesc, createdTime } = workspace;
        return (
          <div
            className={`ai-inference-grid-item  ${isMobileScreen ? "ai-inference-grid-item-mobile " : ""}`}
            key={`aii-grid-item-${index}`}
          >
            <div className="aii-grid-item-name font-weight-bold text-blue">
              {viewName}
            </div>
            <div className="aii-grid-item-name f-13 pt-2">
              <span className="font-weight-bold">Description :</span>{" "}
              <span>{viewDesc}</span>
            </div>
            <div className="aii-grid-item-name font-weight-light f-12 pt-1">
              <span className="font-weight-bold">Last updated:</span>{" "}
              <span>{ageAgo(Number(createdTime))}</span>
            </div>
            <div className="aii-grid-item-link pt-2">
              <button
                className="btn1 arrowhover d-flex align-items-center justify-content-between font-weight-bold w-100"
                onClick={() => onSeeDetails(viewId)}
              >
                Go to Dashboard
                <span>
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 45 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M44.0607 13.0607C44.6464 12.4749 44.6464 11.5251 44.0607 10.9393L34.5147 1.3934C33.9289 0.807612 32.9792 0.807612 32.3934 1.3934C31.8076 1.97918 31.8076 2.92893 32.3934 3.51472L40.8787 12L32.3934 20.4853C31.8076 21.0711 31.8076 22.0208 32.3934 22.6066C32.9792 23.1924 33.9289 23.1924 34.5147 22.6066L44.0607 13.0607ZM0 13.5H43V10.5H0V13.5Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        );
      });
    }
    return (
      <div className="d-flex p-5 justify-content-center align-content-center w-100">
        <CustomLoader />
      </div>
    );
  };
  const onCopyCreds = () => {
    navigator.clipboard.writeText(userInfo.org.zohoPass);
  }

  const render = () => {
    return <>
      {userInfo.org.zohoPass && <p className="d-flex mx-3">Dashboard Credentials : ********* <Link onClick={() => onCopyCreds()}><FontAwesomeIcon icon={faCopy} /></Link></p>}
      <div className="ai-inferenfe-grid">{renderWorkflowList()}</div>;
    </>
  };
  return render();
};

export default InferenceTab;
