import React from 'react';
import constant, { AvailableNodes, NodeType, TemplateStatus } from '../../../../helper/constant';
import { useAIModelContext } from '../../../../context/ai-model.context';
import { useAIWorkflowContext } from '../../../../context/ai-workflow.context';
import { Handle } from '@xyflow/react';
import { renderNodeIcon } from '../../../../helper/helper';
import { Link, useParams } from 'react-router-dom';
import NodePrediction from '../components/NodePrediction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEye } from '@fortawesome/free-solid-svg-icons';
import NodeStatus from '../components/NodeStatus';
import { NodeDeleteButton } from './InputNode';

const WorkflowNode = ({ data, id, workflow }) => {
    const { updateRightMenu, updatePredictionModel } = useAIModelContext();
    const { updateSelecteNode, workflowExecution, selectedNode, workflowEdit } = useAIWorkflowContext();
    const params = useParams();

    const handleNodeClick = () => {
        updateRightMenu(true);
        updateSelecteNode({ id, data })
    };

    const onShowWorkflow = (e) => {
        e.stopPropagation();
    }

    const onApprove = e => {
        e.stopPropagation();
        console.log(data);
    }

    const renderViewResults = () => {
        return (
            <div className='d-flex justify-content-between align-items-center px-2'>
                <Link onClick={onShowWorkflow}
                    className='pb-2 align-self-start fs-10'
                    target='_blank'
                    to={`/${constant.PAGES.HOME.AI_WORKFLOW}/${data.id}`}>{<FontAwesomeIcon icon={faEye} />} View</Link>
                <Link
                    onClick={onApprove}
                    className='pb-2 align-self-center fs-10'>Approve {<FontAwesomeIcon icon={faArrowRight} color='lightgreen' />}</Link>
            </div>
        )
    }

    const renderNodeDetails = () => {
        if (data && data.name) {
            return (
                <div className='d-flex flex-column'>
                    <div className='px-2 pb-2 node-details'>
                        {/* <p className='p-0 m-0 text-black'>Name: <span className='text-secondary'> {data.name}</span></p> */}
                        {data.url && <img src={data.url} alt={data.name} className='flownode-preview' />}
                    </div>
                    {renderViewResults()}
                </div>
            )
        }
    }

    const renderNodePrediction = () => {
        return <NodePrediction id={id} templateId={data.templateId} workflowId={params.id} />
    }
    const renderDeleteButton = () => {
        if (workflowEdit)
            return <NodeDeleteButton id={id} />
    }

    const render = () => {
        if (data) {
            return (
                <>
                    <div className={`dndnode d-flex flex-column gap-1 input ${NodeType.WorkflowNode} `} onClick={handleNodeClick}>
                        {renderDeleteButton()}
                        {data &&
                            <div className='p-2 m-0 node-title d-flex justify-content-between align-items-center' style={{ backgroundColor: AvailableNodes[2].color }}>
                                <div className='d-flex align-items-center gap-1'>
                                    {renderNodeIcon(data.type, 25)}
                                    <p className='p-0 m-0 text-align-left'>{data.name || data.label}</p>
                                </div>
                                {workflow.status === TemplateStatus.COMPLETED && <NodeStatus id={id} />}
                            </div>
                        }
                        {renderNodeDetails()}
                        <Handle
                            type="target"
                            position="left"
                            style={{ background: '#555' }}
                            id={`${id}-target`}
                            isConnectable={true}
                        />
                        <Handle
                            type="source"
                            position="right"
                            style={{ background: '#555' }}
                            id={`${id}-source`}
                            isConnectable={true}
                        />
                    </div>
                    {renderNodePrediction()}
                </>
            )
        }
    }
    return render();
}

export default WorkflowNode