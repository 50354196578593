import React, { useEffect, useState } from "react";
import { useAIModelContext } from "../../../../../context/ai-model.context";
import { useScreenSizeContext } from "../../../../../context/mobile-layout.context";
import { useWebSocket } from "./../../../../../hooks/useWebSocket";
import { SensorType } from "../../../../../helper/constant";
import PredLineChart from "../../../../common/charts/PredLineChart";
import { getMagninudeOfVector, getStats } from "../../../../../helper/helper";

const SensorLogger = ({ showModal }) => {
  const { selectedPredictionModel } = useAIModelContext();
  const { isMobileScreen } = useScreenSizeContext();
  const { status, messages, sendMessage, closeConnection } = useWebSocket(selectedPredictionModel.deviceId);
  const [threshold, setThreshold] = useState({ max: '', min: '' });

  useEffect(() => {
    return () => {
      closeConnection();
    }
  }, []);
  const renderTemperatureChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.channel.includes(selectedPredictionModel.sensor)
      );
      return <TemperatureChart logs={logs} {...threshold} />;
    }
  };

  const renderMicroPhoneChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.channel === SensorType.Microphone
      );
      return <MicrophoneChart logs={logs} {...threshold} />;
    }
  };

  const renderLightSensorChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.channel === SensorType.LightSensor
      );
      return <LightSensorChart logs={logs} {...threshold} />;
    }
  };

  const renderAccelerometerChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.channel === SensorType.Accelarometer
      );
      return <AccelerometerChart logs={logs} {...threshold} />;
    }
  };

  const renderGravityChart = () => {
    if (messages.length) {
      const logs = messages.filter((item) => item.channel === SensorType.Gravity);
      return <AccelerometerChart logs={logs} {...threshold} />;
    }
  };

  const renderGyroscopeChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.channel === SensorType.Gyroscope
      );
      return <AccelerometerChart logs={logs} {...threshold} />;
    }
  };

  const renderMagnetometerChart = () => {
    if (messages.length) {
      const logs = messages.filter(
        (item) => item.channel === SensorType.Magnatometer
      );
      return <AccelerometerChart logs={logs} {...threshold} />;
    }
  };

  const render = () => {
    return (
      <div className="d-flex flex-column gap-2 pb-5">
        <div
          className={`d-flex justify-content-between gap-2 pt-2 ${isMobileScreen ? "flex-column" : ""}`}
        >
          <h5>Sensor Stream</h5>
          <div className="d-flex flex-column gap-1">
            <p className="p-0">
              Device Id :{" "}
              <span className="strong">{selectedPredictionModel.deviceId}</span>
            </p>
          </div>
        </div>
        <ThresholdComponent max={threshold.max} min={threshold.min} onSubmit={(t) => setThreshold({ ...t })} />
        {selectedPredictionModel.zone !== 'internal' && renderTemperatureChart()}
        {selectedPredictionModel.sensor === SensorType.Noise && renderMicroPhoneChart()}
        {selectedPredictionModel.sensor === 'light' && renderLightSensorChart()}
        {selectedPredictionModel.sensor === SensorType.Accelarometer && renderAccelerometerChart()}
        {selectedPredictionModel.sensor === SensorType.Magnatometer && renderMagnetometerChart()}
        {selectedPredictionModel.sensor === SensorType.Gravity && renderGravityChart()}
        {selectedPredictionModel.sensor === SensorType.Gyroscope && renderGyroscopeChart()}
      </div>
    );
  };

  return render();
};

export default SensorLogger;

const MicrophoneChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          data: { dB },
          time,
        } = item;
        data["name"] = index;
        data["dB"] = dB;
        return data;
      });

      return {
        title: logs[0].channel,
        labels: ["Samples", ""],
        datakeys: ["name", "dB"],
        data: transformData,
        stats: getStats(transformData, 'dB')
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} max={props.max} min={props.min} />;
  };
  return render();
};

const TemperatureChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          data: { humidity, temperature, roll },
          time,
        } = item;
        data["name"] = index;
        data["temperature"] = temperature;
        data["humidity"] = humidity;
        return data;
      });

      return {
        title: logs[0].channel,
        labels: ["Samples", ""],
        datakeys: ["name", "temperature", "humidity"],
        data: transformData,
        stats: getStats(transformData, 'temperature')
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} max={props.max} min={props.min} />;
  };
  return render();
};

const AccelerometerChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          data: { x, y, z },
          time,
        } = item;
        data["name"] = index;
        data["x"] = x;
        data["y"] = y;
        data["z"] = z;
        data['magnitude'] = getMagninudeOfVector(x, y, z)
        return data;
      });

      return {
        title: logs[0].channel,
        labels: ["Samples", ""],
        datakeys: ["name", "magnitude", 'x', 'y', 'z'],
        data: transformData,
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} max={props.max} min={props.min} />;
  };
  return render();
};

const LightSensorChart = (props) => {
  const { logs } = props;

  const transformLineConfig = () => {
    if (logs.length) {
      const transformData = logs.map((item, index) => {
        let data = {};
        const {
          data: { light },
          time,
        } = item;
        data["name"] = index;
        data["light"] = light;
        return data;
      });

      return {
        title: logs[0].channel,
        labels: ["Samples", ""],
        datakeys: ["name", "light"],
        data: transformData,
        stats: getStats(transformData, 'light')
      };
    }
  };
  const render = () => {
    const config = transformLineConfig();
    return <PredLineChart config={config} max={props.max} min={props.min} />;
  };
  return render();
};


const ThresholdComponent = (props) => {

  const [threshold, setThreshold] = useState({ max: props.max || '', min: props.min || '' });

  const renderThresholdFieldMax = () => {
    return (
      <div className="d-flex align-items-center gap-2">
        <label htmlFor="thresholdmax" className="m-0">Max: </label>
        <input className="threshold-form-input"
          id="thresholdmax"
          value={threshold.max}
          placeholder="Upper limit"
          onChange={e => setThreshold({ ...threshold, max: e.currentTarget.value })} />
      </div>
    )
  }

  const renderThresholdFieldMin = () => {
    return (
      <div className="d-flex align-items-center gap-2">
        <label htmlFor="thresholdmax" className="m-0">Min: </label>
        <input className="threshold-form-input"
          value={threshold.min}
          placeholder="Lower limit"
          onChange={e => setThreshold({ ...threshold, min: e.currentTarget.value })} />
      </div>
    )
  }

  const rednderUpdate = () => {
    return (
      <button className="btn btn-primary" type="submit">Update</button>
    )
  }

  const onFormSubmit = (e) => {
    e.preventDefault();
    if (threshold.max && threshold.min) {
      props.onSubmit && props.onSubmit(threshold);
    }
  }

  const render = () => {
    return (<form onSubmit={onFormSubmit}>
      <div className="d-flex align-items-center gap-2">
        {renderThresholdFieldMax()}
        {renderThresholdFieldMin()}
        {rednderUpdate()}
      </div>
    </form>
    )
  }
  return render();
}