import React, { useEffect, useState } from "react";
import { useAIWorkflowContext } from "../../../../context/ai-workflow.context";
import { NodeType } from "../../../../helper/constant";
import { useReactFlow } from "@xyflow/react";
import { useAIModelContext } from "../../../../context/ai-model.context";
import CustomDropdown from "../../../common/CustomDropdown";

const WorkflowNodeConfig = (props) => {
    const [forms, setForms] = useState({ workflowId: null });
    const [flowData, setFlowData] = useState({ id: '', name: '', url: '' })
    const { setNodes } = useReactFlow();
    const { updateRightMenu } = useAIModelContext();
    const { selectedNode, workflows, workflowEdit } = useAIWorkflowContext();

    useEffect(() => {
        if (selectedNode && selectedNode.data) {
            setForms({
                workflowId: selectedNode.data.id
            });
        }

    }, [selectedNode]);

    useEffect(() => {
        if (forms.workflowId && workflows) {
            const item = workflows.filter(i => i.id === forms.workflowId)[0];
            setFlowData(item);
        }
    }, [forms.workflowId])

    const renderWorkflowList = () => {
        return (
            <div>
                <CustomDropdown
                    name="Existing Workflow"
                    options={workflows}
                    field="name"
                    value={forms.workflowId}
                    onChange={workflowId => setForms({ ...forms, workflowId })}
                />
            </div>
        )
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const { id } = selectedNode;

        setNodes((nodes) => nodes.map((node) =>
            node.id === id
                ? { ...node, data: { ...node.data, id: flowData.id, name: flowData.name, url: flowData.url } }
                : node
        ));
        updateRightMenu(false);
    }

    const renderSaveButton = () => {
        return (
            <div className="form-group">
                <button
                    type="submit"
                    className="btn2 btn radious-0 w-100 f-13 text-uppercase py-2 font15 fontweight600">
                    save
                </button>
            </div>
        )
    }
    const renderConfigForm = () => {
        return (
            <form className="d-flex flex-column gap-2" onSubmit={onFormSubmit} noValidate>
                {renderWorkflowList()}
                {renderSaveButton()}
            </form>
        )
    }

    const renderConfigrationStatus = () => {
        console.log(selectedNode.data.input_type);

        return (
            <div className="d-flex flex-column flex-wrap gap-1">
                <p className="p-0 m-0">
                    <span className="text-black">Workflow name :</span>
                    <span className="text-secondary capitalize"> {selectedNode.data.name}</span>
                </p>
            </div>
        )
    }

    const render = () => {
        return (
            <div className="d-flex flex-column flex-wrap gap-3 pt-3">
                <h5>Workflow Node Configuration</h5>
                {workflowEdit ? renderConfigForm() : renderConfigrationStatus()}
            </div>
        )
    }
    return render();

}

export default WorkflowNodeConfig