import { useEffect, useRef, useState } from "react";
import { SOCKET } from "../helper/constant";

export const useWebSocket = (deviceId) => {
  const [messages, setMessages] = useState([]);
  const [status, setStatus] = useState("DISCONNECTED");
  const socketRef = useRef(null);
  const socketURL = useRef(`${process.env.REACT_APP_SOCKET_END_POINT}`);

  useEffect(() => {
    let socket;
    console.log(process.env.REACT_APP_SOCKET_END_POINT);
    if (socketURL.current) {
      const socket = new WebSocket(
        `${socketURL.current.replace("{deviceId}", deviceId)}`
      );
      socketRef.current = socket;

      socket.onopen = () => {
        setStatus(SOCKET.CONNECTED);
        console.log("WebSocket connected");
      };

      socket.onmessage = (event) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          JSON.parse(event.data),
        ]);
      };

      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socket.onclose = () => {
        setStatus(SOCKET.DISCONNECTED);
        console.log("WebSocket disconnected");
      };
    }

    return () => {
      socket && socket.close();
    };
  }, [deviceId, socketURL.current]);

  const closeConnection = () => {
    socketURL.current && socketRef.current.close();
  }

  const sendMessage = (message) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(message);
    } else {
      console.error("WebSocket is not open");
    }
  };

  return { status, messages, sendMessage, closeConnection };
};

export default useWebSocket;
