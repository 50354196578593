import React, { useEffect, useState } from "react";
import { useAIWorkflowContext } from "../../../../context/ai-workflow.context";
import { useReactFlow } from "@xyflow/react";
import { Form } from "react-bootstrap";
import CustomDropdown from "../../../common/CustomDropdown";
import { useAIModelContext } from "../../../../context/ai-model.context";
import constant, { InputTypes, ToastType } from "../../../../helper/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { Link, useParams } from "react-router-dom";
import { createWorkflowAPI, deleteScheduleWorkflowAPI, getAISensesDevicesAPI, getAISensesZonesAPI, getCloudStorageFileColumnAPI, getCloudStorageFilesAPI } from "../../../../service/ai-model.service";
import { useAppContext } from "../../../../context/app.context";
import { useAuthContext } from "../../../../context/auth.context";

const cronRegex = /^(\*|([0-5]?[0-9])|([0-5]?[0-9]\/[1-9][0-9]*))?(\s+(\*|([0-1]?[0-9]|2[0-3])|([0-1]?[0-9]|2[0-3]\/[1-9][0-9]*))?)?(\s+(\*|([1-9]|[12][0-9]|3[01])))?(\s+(\*|([1-9]|1[0-2]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))?(\s+(\*|([0-7]|SUN|MON|TUE|WED|THU|FRI|SAT)))?$/;


const InputNodeConfig = (props) => {
    const [forms, setForms] = useState({
        inputType: '',
        llm: '',
        prompt: '',
        scrapping_url: '',
        fieldMissing: false,
        schedule: '',
        file: '',
        device: '',
        zone: '',
        sensor: ''
    });
    const [error, setError] = useState('')
    const { setNodes, getEdges, getNodes } = useReactFlow();
    const { userInfo } = useAuthContext();
    const params = useParams();
    const { updateRightMenu } = useAIModelContext();
    const { showAlert, updateLoader } = useAppContext();
    const [fileList, setFileList] = useState([]);
    const [fileColumns, setFileColumns] = useState([]);
    const { selectedNode, getWorkflowInputTypes, inputTypes, llmList, getWorkflowLLMList, workflowEdit, updateSelecteNode } = useAIWorkflowContext();

    useEffect(() => {
        getWorkflowInputTypes();
        getWorkflowLLMList();
    }, []);

    useEffect(() => {
        if (forms.inputType && forms.inputType === InputTypes.CloudStorage) {
            getCloudStorageData();
        }
    }, [forms.inputType]);

    const getCloudStorageData = () => {
        getCloudStorageFilesAPI().then((res) => {
            if (res && res.length) {
                setFileList(res);
            }
        }).catch(e => showAlert(JSON.stringify(e), ToastType.ERROR))
    }

    useEffect(() => {
        if (forms.inputType && forms.inputType === InputTypes.CloudStorage) {
            if (forms.file) {
                getCloudStorageFileColumnAPI(forms.file)
                    .then((res) => setFileColumns(res))
                    .catch(e => console.log(JSON.stringify(e)))
            }
        }
    }, [forms.file, forms.inputType])

    useEffect(() => {
        if (selectedNode && selectedNode.data) {
            setForms({
                inputType: selectedNode.data.inputType,
                llm: selectedNode.data.llm,
                prompt: selectedNode.data.prompt,
                scrapping_url: selectedNode.data.scrapping_url,
                fieldMissing: selectedNode.data.fieldMissing,
                schedule: selectedNode.data.schedule,
                file: selectedNode.data.file,
                device: selectedNode.data.device,
                sensor: selectedNode.data.sensor,
                zone: selectedNode.data.zone
            });
        }

    }, [selectedNode]);

    const renderInputType = () => {
        return (
            <div>
                <CustomDropdown
                    name="Input Type"
                    options={inputTypes}
                    field="value"
                    value={forms.inputType}
                    onChange={inputType => setForms({ ...forms, inputType })}
                />
            </div>
        )
    }



    const renderLLMType = () => {
        return (
            <div>
                <CustomDropdown
                    name="LLM Type"
                    options={llmList}
                    field="value"
                    value={forms.llm}
                    onChange={llm => setForms({ ...forms, llm })}
                />
            </div>
        )
    }

    const renderFileList = () => {
        return (
            <div>
                <CustomDropdown
                    name="File"
                    options={fileList}
                    field=""
                    value={forms.file}
                    onChange={file => setForms({ ...forms, file })}
                />
            </div>
        )
    }

    const renderPrompt = () => {
        return (
            <div className="d-flex flex-column"
            >
                <div className="custom-dropdown-label text-blue">
                    <b>Prompt</b>
                </div>
                <textarea
                    rows={2}
                    style={{ minHeight: 80 }}
                    className={`form-control`}
                    placeholder="LLM prompt for better data sanity"
                    value={forms.prompt}
                    onChange={(e) => setForms({ ...forms, prompt: e.currentTarget.value })}
                />
            </div>
        )
    }

    const renderSchedular = () => {
        return (
            <div className="d-flex flex-column"
            >
                <div className="custom-dropdown-label text-blue">
                    <b>Schedule</b>
                </div>
                <input
                    className={`form-control ${error ? 'error-br' : ''}`}
                    placeholder="* * * * *"
                    value={forms.schedule}
                    onChange={(e) => setForms({ ...forms, schedule: e.currentTarget.value })}
                />
                <small className="hint pt-1">Please use cron format. Example for every 30 minutes (*/30 * * * *)</small>
            </div>
        )
    }

    const renderWebsiteURL = () => {
        return (
            <div className="d-flex justify-content-between align-items-center"
            >
                <input
                    className={`form-control`}
                    placeholder="Website URL"
                    value={forms.scrapping_url}
                    autoCorrect="false"
                    autoCapitalize="false"
                    autoComplete="no"
                    onChange={(e) => setForms({ ...forms, scrapping_url: e.currentTarget.value })}
                />
            </div>
        )
    }

    const renderFieldMissing = () => {
        return (
            <Form.Check
                type="checkbox"
                id='fieldMissing'
                label="Few fields missing"
                value={forms.fieldMissing}
                onChange={() => setForms({ ...forms, fieldMissing: !forms.fieldMissing })}
            />
        )
    }

    const handleValidation = () => {
        let isValid = true;
        if (forms.schedule && !cronRegex.test(forms.schedule)) {
            setError(true);
            isValid = false;
        } else {
            setError(false)
        }
        return isValid
    }

    const onFormSubmit = e => {
        e.preventDefault();
        const { id } = selectedNode;
        const input = inputTypes.filter(item => item.id === forms.inputType)[0];
        const llmItem = llmList.filter(item => item.id === forms.llm)[0];
        setNodes((nodes) => nodes.map((node) =>
            node.id === id
                ? { ...node, data: { ...node.data, ...forms, input_type: input.value, url: input.endpoint, llm_type: llmItem?.model_type, file_columns: fileColumns } }
                : node
        ));
        updateRightMenu(false);

    }

    const renderSaveButton = () => {
        return (
            <div className="form-group">
                <button
                    type="submit"
                    className="btn2 btn radious-0 w-100 f-13 text-uppercase py-2 font15 fontweight600">
                    save
                </button>
            </div>
        )
    }

    const renderDynamicInputConfig = () => {
        switch (forms.inputType) {
            case InputTypes.DataScrapping:
                return (
                    <>
                        {renderLLMType()}
                        {renderWebsiteURL()}
                        {renderPrompt()}
                        {renderSchedular()}
                        {renderFieldMissing()}
                    </>
                )
            case InputTypes.CloudStorage:
                return (
                    <>
                        {renderFileList()}
                        {renderFileColumns(fileColumns)}
                    </>
                )
            case InputTypes.AISenses:
                return (
                    <>
                        <AISensesInput forms={forms} setForms={setForms} />
                    </>
                )
            default:
                return <></>
        }
    }

    const renderConfigForm = () => {
        return (
            <form className="d-flex flex-column gap-2" onSubmit={onFormSubmit} noValidate>
                {renderInputType()}
                {renderDynamicInputConfig()}
                {renderSaveButton()}
            </form>
        )
    }
    const getWorkflowName = () => `workflow-${userInfo.org.org_name}${props.name}`;

    const onSheduelDelete = () => {
        updateLoader(true);
        deleteScheduleWorkflowAPI({ name: getWorkflowName() })
            .then(() => {
                updateSchedule();
                showAlert(constant.MESSAGES.DELETED_MESSAGE, ToastType.SUCCESS)
            })
            .catch((r) => showAlert(constant.MESSAGES.COMMON_ERROR_MESSAGE, ToastType.SUCCESS))
            .finally(() => updateLoader(false));
    }

    const updateSchedule = () => {
        const { id } = selectedNode;
        const nodes = getNodes();
        const edges = getEdges();
        let updateNodes = nodes.map((node) => {
            if (node.id === id) {
                const { schedule, ...nodeInfo } = node.data;
                const updatedNode = {
                    ...node,
                    data: { ...nodeInfo }
                }
                updateSelecteNode(updatedNode);
                return updatedNode;
            }
            return node;
        });

        setNodes((nodes) => updateNodes.slice());
        createWorkflowAPI({ name: props.name, template: { edges, nodes: updateNodes } }, params.id)
            .then((r) => r)
    }

    const renderFileColumns = (list) => {
        if (list.length)
            return (
                <p className="p-0 m-0">
                    <p className="p-0 m-0 text-black">File Columns :</p>
                    <ul className="list-disc pl-4">
                        {list.map(item => <li className="p-0 m-0 text-secondary"> {item}</li>)}
                    </ul>
                </p>
            )
    }

    const renderConfigrationStatus = () => {
        console.log(selectedNode.data.input_type);

        return (
            <div className="d-flex flex-column flex-wrap gap-1">
                <p className="p-0 m-0">
                    <span className="text-black">Input Type :</span>
                    <span className="text-secondary capitalize"> {selectedNode.data.input_type}</span>
                </p>

                {
                    (selectedNode.data.input_type == 'data scraping') &&
                    <>
                        <p className="p-0 m-0">
                            <span className="text-black">LLM Type :</span>
                            <span className="text-secondary"> {selectedNode.data.llm_type}</span>
                        </p>
                        <p className="p-0 m-0">
                            <span className="text-black">Scraping URL :</span>
                            <a className="" href={selectedNode.data.scrapping_url} target="_blank" rel="noopener noreferrer"> Scraping URL</a>
                        </p>
                        <p className="p-0 m-0">
                            <span className="text-black">Prompt :</span>
                            <span className="text-secondary"> {selectedNode.data.prompt || '--'}</span>
                        </p>
                        {selectedNode.data.schedule &&
                            <p className="p-0 m-0 d-flex align-items-center justify-content-between">
                                <p className="p-0 m-0">
                                    <span className="text-black">Schedule :</span>
                                    <span className="text-secondary"> {selectedNode.data.schedule || '--'}</span>
                                </p>
                                <Link onClick={() => onSheduelDelete()}><FontAwesomeIcon icon={faTrashCan} className="text-danger" /></Link>
                            </p>
                        }
                        <p className="p-0 m-0">
                            <span className="text-black">Fied Missing :</span>
                            <span className="text-secondary"> {selectedNode.data.fieldMissing ? 'Yes' : 'No'}</span>
                        </p>
                    </>
                }
                {
                    (selectedNode.data.input_type == 'cloud storage') &&
                    <>
                        <p className="p-0 m-0">
                            <span className="text-black">File Selected :</span>
                            <span className="text-secondary"> {selectedNode.data.file}</span>
                        </p>
                        {renderFileColumns(selectedNode.data.file_columns)}
                    </>
                }
                {
                    (selectedNode.data.input_type == 'ai senses') &&
                    <>
                        <p className="p-0 m-0">
                            <span className="text-black">Connected Device :</span>
                            <span className="text-secondary"> {selectedNode.data.device}</span>
                        </p>
                        <p className="p-0 m-0">
                            <span className="text-black">Zone :</span>
                            <span className="text-secondary"> {selectedNode.data.zone}</span>
                        </p>
                        <p className="p-0 m-0">
                            <span className="text-black">Attributes :</span>
                            <span className="text-secondary"> {selectedNode.data.attribute}</span>
                        </p>
                    </>
                }
            </div>
        )
    }



    const render = () => {
        return (
            <div className="d-flex flex-column flex-wrap gap-3 pt-3">
                <h5>Input Node Configuration</h5>
                {workflowEdit ? renderConfigForm() : renderConfigrationStatus()}
            </div>
        )
    }
    return render();

}

export default InputNodeConfig

const AISensesInput = ({ forms, setForms }) => {
    const [devices, setDevices] = useState([]);
    const [zoneList, setZoneList] = useState([]);
    const [sensorList, setSensorList] = useState([]);
    const { showAlert, updateLoader } = useAppContext();

    useEffect(() => {
        if (forms.inputType && forms.inputType === InputTypes.AISenses) {
            getAISensesDevices()
        }
    }, [forms.inputType]);

    useEffect(() => {
        if (forms.device) {
            getAISensesZonesAPI({ deviceId: forms.device })
                .then(result => {
                    if (result && result.zones && result.zones.length) {
                        const list = result.zones.map(i => ({ id: i.id, name: i.id, channels: i.channels }));
                        setZoneList(list);
                    }
                })
                .catch(console.log);
        }
    }, [forms.device]);

    useEffect(() => {
        if (forms.zone) {
            updateSensorList();
        }
    }, [forms.zone, zoneList]);

    const updateSensorList = () => {
        if (forms.zone) {
            const list = zoneList.slice();
            const zone = list.filter(i => i.id === forms.zone);
            if (zone.length) {
                const attrList = zone[0].channels.flatMap((item) => {
                    return item.sensors;
                });
                console.log(attrList);
                setSensorList(attrList);
            }
        }
    }

    const getAISensesDevices = () => {
        getAISensesDevicesAPI().then((res) => {
            if (res && res.length) {
                setDevices(res);
            }
        }).catch(e => showAlert(JSON.stringify(e), ToastType.ERROR))
    }


    const renderZonesList = () => {
        if (zoneList && zoneList.length)
            return (
                <div>
                    <CustomDropdown
                        name="Zones"
                        options={zoneList}
                        field="id"
                        value={forms.zone}
                        onChange={zone => setForms({ ...forms, zone })}
                    />
                </div>
            )
    }

    const renderSensorsList = () => {
        if (sensorList && sensorList.length)
            return (
                <div>
                    <CustomDropdown
                        name="Sensors"
                        options={sensorList}
                        field="id"
                        value={forms.sensor}
                        onChange={sensor => setForms({ ...forms, sensor })}
                    />
                </div>
            )
    }

    const renderDeviceList = () => {
        return (
            <div>
                <CustomDropdown
                    name="Device"
                    options={devices}
                    field="id"
                    value={forms.device}
                    onChange={device => setForms({ ...forms, device })}
                />
            </div>
        )
    }

    const render = () => {
        return (
            <>
                {renderDeviceList()}
                {renderZonesList()}
                {renderSensorsList()}
            </>
        )
    }

    return render();
}